export const environmentData = {
  run: 'development',
  development: {
    clientId: '9e9325f7-4c4d-43e6-a9f4-a47ba623cc92',
    authority: 'https://login.microsoftonline.com/30f52344-4663-4c2e-bab3-61bf24ebbed8',
    tenantId: '30f52344-4663-4c2e-bab3-61bf24ebbed8',
    'api-scope': 'openid 9e9325f7-4c4d-43e6-a9f4-a47ba623cc92/User.Impersonation',
    apiBaseURL:
      'https://sw-enterprise-communication-prod-api-ghg7gwhwe9fcb0hv.westeurope-01.azurewebsites.net/api/',
  },
  staging: {
    clientId: '9e9325f7-4c4d-43e6-a9f4-a47ba623cc92',
    authority: 'https://login.microsoftonline.com/30f52344-4663-4c2e-bab3-61bf24ebbed8',
    tenantId: '30f52344-4663-4c2e-bab3-61bf24ebbed8',
    'api-scope': 'openid 9e9325f7-4c4d-43e6-a9f4-a47ba623cc92/User.Impersonation',
    apiBaseURL:
      'https://sw-enterprise-communication-prod-api-ghg7gwhwe9fcb0hv.westeurope-01.azurewebsites.net/api/',
  },
  production: {
    clientId: '9e9325f7-4c4d-43e6-a9f4-a47ba623cc92',
    authority: 'https://login.microsoftonline.com/30f52344-4663-4c2e-bab3-61bf24ebbed8',
    tenantId: '30f52344-4663-4c2e-bab3-61bf24ebbed8',
    'api-scope': 'openid 9e9325f7-4c4d-43e6-a9f4-a47ba623cc92/User.Impersonation',
    apiBaseURL:
      'https://sw-enterprise-communication-prod-api-ghg7gwhwe9fcb0hv.westeurope-01.azurewebsites.net/api/',
  },
}
